import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { colors, margins, fontSizes, mobileThresholdPixels } from '../styledComponents';

const imageWidth = 450;
const Img = styled.img`
  width: ${imageWidth}px;
  height: 550px;
  object-fit: cover;

  @media (max-width: ${mobileThresholdPixels}) {
    width: ${imageWidth / 3}px;
    height: 200px;
  }
`;

const Content = styled.div`
  position: relative;
  margin: ${props => !props.right ?
    `${margins.xl} 0px 0px ${margins.xl}` :
    `-${margins.l} ${margins.xl} 0px 0px`
  };

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s};
    margin: ${props => !props.right ?
      `${margins.m} 0px 0px ${margins.m}` :
      `-${margins.m} ${margins.m} 0px 0px`
    };
  }
`;

const Title = styled.span`
  position: absolute;
  font-family: Gotham;
  font-weight: bold;
  text-transform: uppercase;
  ${props => !props.right ? 'right: 0px' : 'left: 0px'};
  margin: ${props => !props.right ?
    `-${margins.m} ${margins.m} 0px 0px` :
    `-${margins.m} 0px 0px ${margins.m}`
  };
  color: ${colors.navy};
  font-size: ${fontSizes.x3l};
  width: 100%;
  text-align: ${props => !props.right ? 'right' : 'left'};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${props => !props.right ? '-6px 6px 0px 0px' : '-6px 0px 0px 6px'};
    font-size: ${fontSizes.s};
  }
`;

const ListInterview = ({ interview, right }) => (
  <Link to={interview.path} style={{ textDecoration: 'none' }}>
    <Content right={right}>
      <Title right={right}>{interview.title}</Title>
      <Img src={interview.listImage && interview.listImage.publicURL} alt={interview.listImageAlt} />
    </Content>
  </Link>
);

ListInterview.propTypes = {
  interview: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    listImageAlt: PropTypes.string.isRequired,
    listImage: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  right: PropTypes.bool.isRequired,
};

export default ListInterview;
