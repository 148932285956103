import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Layout from '../layouts/index';
import MenuBar from '../components/home/MenuBar';
import List from '../components/OurArtisans/List';
import Footer from '../components/home/Footer';
import { mobileThresholdPixels, margins, colors, fontSizes, Container, Content }
  from '../components/home/v2/StyledComponents';

const Title1 = styled.h1`
  font-family: Gotham;
  text-transform: uppercase;
  text-align: center;
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  margin: ${margins.xl} 0px;
  letter-spacing: 5px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.l} 0px;
  }
`;

const ListTemplate = ({ pageContext: { interviews } }) => (
  <Layout routeSlug="LaRubrique">
    <Container>
      <MenuBar />
      <Content>
        <Title1>#MyTilliste</Title1>
        <List interviews={interviews} />
      </Content>
      <Footer />
    </Container>
  </Layout>
);

ListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    interviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default ListTemplate;
